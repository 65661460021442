import React, { useEffect, useState } from 'react'
import cruds from '../../styles/CRUDS.module.css'
import axios from '../../AxiosInstance'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'

export default function EmptySilo() {
    const [silos, setSilos] = useState([]);
    const [error, setError] = useState("");

    const [form, setForm] = useState({});
    const setField = (field, value) => {
        setForm({
        ...form, 
        [field]:value
        });
    };

    useEffect(() => {
        axios.get('silo_select/').then((response) => {
            setSilos(response.data)
        });
    }, []);

    const submit = () => {
        axios.post('vaciadosilo/', {
            lugar_salida: form.lugar_salida,
            kg_vaciado: form.kg_vaciado,
            silo_id: form.silo_id
        }).then((response) => {
            setError("")
            window.location.replace('/silo')
        }).catch((err) => {
            setError("Por favor revise todos los campos");
        })
    }
  return (
    <div>
        <div className={`${cruds.content} pt-5`}>
            <h1>Vaciado de Silos</h1>

            <div className={`${cruds.inputs}`}>
                <div className={`${cruds.input_container} pb-4`}>
                    <label className={cruds.text_input_label}>Fecha y Hora Vaciado</label>
                    <input type="datetime-local" className={cruds.text_input} onChange={x => setField("hora_vaciado", x.target.value)}></input>
                </div>
                <div className={`${cruds.input_container} pb-4`}>
                    <label className={cruds.text_input_label}>Silo</label>
                    <select className={cruds.select_input} onChange={x => setField("silo_id", x.target.value)}>
                        <option value="">-- Seleccione una opción --</option>
                        {silos.map((silo, index) => (
                            <option key={index} value={silo.id}>{silo.nombre}</option>
                        ))}
                    </select>
                </div>
                <div className={`${cruds.input_container} pb-4`}>
                    <label className={cruds.text_input_label}>KG Salida</label>
                    <input type="number" className={cruds.text_input} onChange={x => setField("kg_vaciado", x.target.value)}></input>
                </div>
                <div className={`${cruds.input_container} pb-4`}>
                    <label className={cruds.text_input_label}>Lugar Salida</label>
                    <input className={cruds.text_input} onChange={x => setField("lugar_salida", x.target.value)}></input>
                </div>

                <div className={`${cruds.buttons_in_row}`}>
                    <Link className={`${cruds.save_button}`} to="/silo">
                        <Button>
                            <FontAwesomeIcon icon={faChevronLeft} color="black"/>
                            <span>Regresar</span>
                        </Button>
                    </Link>
                    <div className={`${cruds.save_button}`}>
                        <Button onClick={submit}>
                            <span>Guardar</span>
                        </Button>
                    </div>
                    <Link className={`${cruds.save_button}`} to="/silo/empty/history">
                        <Button onClick={submit}>
                            <span>Historial</span>
                        </Button>
                    </Link>
                </div>
                <div className={`${cruds.error_span} pt-3`}>
                    <span>{error}</span>
                </div>
            </div>
        </div>
    </div>
  )
}
