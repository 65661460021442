import React, { useEffect, useState } from 'react'
import getURLParams from '../../utils/getURLParams';
import cruds from '../../styles/CRUDS.module.css'
import axios from '../../AxiosInstance'
import { Button } from 'react-bootstrap'
import LoadingComponent from '../../components/LoadingComponent';
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'

export default function EditProducer() {
    const params = getURLParams();
    const [name, setName] = useState("");
    const [trucks, setTrucks] = useState([]);
    const [selectedTruck, setSelectedTruck] = useState(0);
    const [producerTrucks, setProducerTrucks] = useState([]);
    const [displayTruckNames, setDisplayTruckNames] = useState([]);
    const [error, setError] = useState("");
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {

        axios.get('productor/' + params.id + "/").then((response) => {
            const data = response.data;
            setName(data.nombre);
        });

        axios.post('get_productorcamion/', {
            productor_id: params.id
        }).then((response) => {
            const data = response.data;
            const ids = data.map((item) => item.id);
            const identificadores  = data.map((item) => item.identificador);

            setProducerTrucks(ids);
            setDisplayTruckNames(identificadores);

            axios.get('camion_select/').then((response) => {
                const data = response.data;
                const filtered = data.filter(item => !ids.includes(item.id));
                setTrucks(filtered);
                setLoaded(true);
            })
        })
    }, []);

    const addTruck = () => {
        if(selectedTruck != 0) {
          if(!producerTrucks.includes(selectedTruck)) {
            const newTrucks = [...producerTrucks, selectedTruck];
            setProducerTrucks(newTrucks);
            const result = trucks.find(obj => obj.id == selectedTruck);
            if (result)
            {
              const newTruckNames = [...displayTruckNames, result.identificador]; 
              setDisplayTruckNames(newTruckNames);
            }
          }
        }
      }

    const submit = () => {
        axios.put('productor/' + params.id + "/", {
          nombre: name,
          camiones: producerTrucks
        }).then((response) => {
          setError("")
          window.location.replace('/producer')
        }).catch((err) => {
          setError("Por favor revise todos los campos");
        })
      }
  return (
    <div>
        {loaded === false ? <LoadingComponent/> :
        <div className={`${cruds.content} pt-5`}>
            <h1>Editar productor</h1>
            <div className={`${cruds.inputs}`}>
                <div className={`${cruds.input_container} pb-4`}>
                    <label className={cruds.text_input_label}>Nombre de nuevo productor</label>
                    <input className={cruds.text_input} onChange={x => setName(x.target.value)} value={name}></input>
                </div>
                <div className={`${cruds.input_container} pb-4`}>
                    <label className={cruds.text_input_label}>Camión</label>
                    <select className={cruds.select_input} onChange={x => setSelectedTruck(x.target.value)}>
                        <option value="">-- Seleccione una opción --</option>
                        {trucks.map((truck, index) => (
                          <option key={index} value={truck.id}>{truck.identificador}</option>
                        ))}
                    </select>
                </div>
                <div className={`${cruds.secondary_button} pb-3`}>
                  <Button onClick={addTruck}>
                      <span>Añadir Camión</span>
                  </Button>
                </div>
                
                <div className={`${cruds.names_box}`}>
                {displayTruckNames.map((truck, index) => (
                  <div key={index}>{truck}</div>
                ))}
                </div>

                <div className={`${cruds.buttons_in_row} pt-3`}>
                  <Link className={`${cruds.save_button}`} to="/producer">
                    <Button>
                      <FontAwesomeIcon icon={faChevronLeft} color="black"/>
                      <span>Regresar</span>
                    </Button>
                  </Link>

                  <div className={`${cruds.save_button} pt-3`}>
                    <Button onClick={submit}>
                      <span>Guardar</span>
                    </Button>
                  </div>
                </div>
                <div className={`${cruds.error_span} pt-3`}>
                    <span>{error}</span>
                </div>
            </div>
        </div>}
    </div>
  )
}
