import axios from "axios";

const baseURL = "https://lister.arrozchispa.com";
const apiURL = `${baseURL}/api/`;

const axiosInstance = axios.create({
    baseURL: apiURL
});

const setAuthorizationHeader = async () => {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }
};

setAuthorizationHeader();

export default axiosInstance;