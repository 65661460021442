import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Router from './RouteContainer';
import SidebarComponent from "./components/SidebarComponent";
import React from "react";
import HeaderComponent from "./components/HeaderComponent";

function App() {
    const [sidebarOpen, setSidebarOpen] = React.useState(true);

    const omittedPaths = ["/login"];
    const path = window.location.pathname;

    return (
        <div className="App">
            <BrowserRouter>
                {!omittedPaths.includes(path) ?
                    <React.Fragment>
                        <HeaderComponent setSidebarOpen={setSidebarOpen} />
                        {sidebarOpen && <SidebarComponent/>}
                        <div style={{
                            marginLeft: sidebarOpen ? "calc(250px + 1.5rem)" : "1.5rem",
                            marginRight: "1.5rem",
                            backgroundColor: "var(--smoke-white)",
                        }}>
                            <Router/>
                        </div>
                    </React.Fragment>
                    :
                    <div style={{backgroundColor: "var(--smoke-white)",}}>
                        <Router/>
                    </div>
                }
            </BrowserRouter>
        </div>
    );
}

export default App;
