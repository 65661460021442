import React, { useEffect, useState } from 'react'
import styles from '../styles/Paddy.module.css'
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import axios from '../AxiosInstance'
import LoadingComponent from '../components/LoadingComponent'
import PaginationComponent from '../components/PaginationComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'

export default function FillHistoryScreen() {
    const [producers, setProducers] = useState([]);
    const [tanks, setTanks] = useState([]);
    const [paddys, setPaddys] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const [dateFilter, setDateFilter] = useState(undefined);
    const [tankFilter, setTankFilter] = useState(undefined);
    const [producerFilter, setProducerFilter] = useState(undefined);

    useEffect(() => {
        axios.get('llenado/').then((response) => {
            setPaddys(response.data.results);
            setLoaded(true);
        })

        axios.get('tanque_select/').then((response) => {
            setTanks(response.data);
        })

        axios.get('productor_select/').then((response) => {
            setProducers(response.data);
        })
    }, []);

    const search = () => {
        let url = "llenado/?";
        if(dateFilter !== undefined) url += '&fecha_hora=' + dateFilter;
        if(tankFilter !== undefined) url += '&tanque=' + tankFilter;
        //if(activeFilter !== undefined) url += '&activo=' + activeFilter;
        if(producerFilter !== undefined) url += '&productor=' + producerFilter;
        axios.get(url).then((response) => {
            setPaddys(response.data.results);
            setLoaded(true);
        })
    }

    const pullData = (data) => {
        setPaddys(data);
    }

    const readableTime = (data) => {
        if(data == null) return "-";
        const date = new Date(data);
        return date.toLocaleString();
    }
  return (
    <div>
      {loaded === false ? <LoadingComponent/>:
      <div className={`${styles.content} pt-5`}>
        <h1>Historial de llenado</h1>
        <div className={`${styles.inner_container} pt-5`}>

            <div className={`${styles.filter_top}`}>
                <div className={`${styles.text_input_container} pb-3`}>
                    <label className={styles.text_input_label}>Fecha</label>
                    <input type="date" className={styles.text_input} onChange={x => setDateFilter(x.target.value)}></input>
                </div>
                <div className={`${styles.text_input_container} pb-3`}>
                    <label className={styles.text_input_label}>Tanque</label>
                    <select className={styles.select_input} onChange={x => setTankFilter(x.target.value)}>
                        <option value="">-- Seleccione una opción --</option>
                        {tanks.map((tank, index) => (
                            <option value={tank.id}>{tank.nombre}</option>
                        ))}
                    </select>
                </div>
                <div className={`${styles.text_input_container} pb-3`}>
                    <label className={styles.text_input_label}>Productores</label>
                    <select className={styles.select_input} onChange={x => setProducerFilter(x.target.value)}>
                        <option value="">-- Seleccione una opción --</option>
                        {producers.map((producer, index) => (
                            <option value={producer.id}>{producer.nombre}</option>
                        ))}
                    </select>
                </div>
                <div className={`${styles.button_container}`}>
                    <Button onClick={search}>
                        <FontAwesomeIcon icon={faSearch} color="black"/>
                    </Button>
                </div>
            </div>

            <table>
                <tr>
                    <th/>
                    <th>Tanque/Lister</th>
                    <th>Productor/Camión</th>
                    <th>KG estimados</th>
                    <th>Humedad Rec.</th>
                    <th>Temperatura Rec.</th>
                    <th>Impureza</th>
                    <th>Fecha y Hora</th>
                </tr>
                {paddys.map((paddy, index) => (
                <tr key={paddy.id}>
                    <th>{index + 1}</th>
                    <th>{paddy.tanque.nombre}</th>
                    <th>{paddy.productor.nombre} / {paddy.camion.identificador}</th>
                    <th>{paddy.kg_estimados}</th>
                    <th>{paddy.humedad_recepcion}</th>
                    <th>{paddy.temperatura_recepcion}</th>
                    <th>{paddy.impureza_recepcion}</th>
                    <th>{readableTime(paddy.fecha_hora)}</th>
                </tr>
                ))}
            </table>

            <PaginationComponent prop={"llenado"} func={pullData} limit={14}/>

            <div className={`${styles.lower_bar}`}>
                <Link className={`${styles.lower_button_container}`} to="/tank">
                    <Button>
                        <FontAwesomeIcon icon={faChevronLeft} color="black"/>
                        <span>Regresar</span>
                    </Button>
                </Link>
            </div>
        </div>
      </div>}
    </div>
  )
}
