import React from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHomeAlt } from '@fortawesome/free-solid-svg-icons/faHomeAlt'
import { faTruck } from '@fortawesome/free-solid-svg-icons/faTruck'
import { faWarehouse } from '@fortawesome/free-solid-svg-icons/faWarehouse'
import { faWheatAwn } from '@fortawesome/free-solid-svg-icons/faWheatAwn'
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons/faRightFromBracket'
import styles from '../styles/Sidebar.module.css'

export default function SidebarComponent() {

    const buttonList = [
        {
            name: "Inicio",
            icon: faHomeAlt,
            goto: '/'
        },
        {
            name: "Recepción",
            icon: faTruck,
            goto: '/arrival'
        },
        {
            name: "Secado",
            icon: faWheatAwn,
            goto: '/tank'
        },        
        {
            name: "Silos",
            icon: faWarehouse,
            goto: '/silo'
        }
    ]
    const logout = () => {
        localStorage.clear();
        window.location.reload();
    }
  return (
    <div className={`${styles.container}`}>
        <div className='pt-5 mt-5'>
        <div className='pt-4'/>
        {buttonList.map((button, index) => (
            <Link key={index} to={button.goto} style={{textDecoration: 'none'}}>
                <Button className={`${styles.button_green} mb-1`}>
                        <FontAwesomeIcon icon={button.icon} color="black"/>
                        <span>{button.name}</span>
                </Button>
            </Link>
        ))}
        <Button onClick={logout} className={`${styles.button_green} mb-1`}>
            <FontAwesomeIcon icon={faRightFromBracket} color="black"/>
            <span>Cerrar sesión</span>
        </Button>
        </div>
    </div>
  )
}
