import React, { useState } from 'react'
import styles from '../styles/Login.module.css';
import { Row, Button } from 'react-bootstrap';
import axios from '../AxiosInstance'

export default function LoginScreen() {
    const [submitting, setSubmitting] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const login = async () => {
        if(submitting) return;
        setSubmitting(true);
        const response = await axios.post('token/', {
            username: username,
            password: password,
        }).finally(() => setSubmitting(false));

        if(response.status === 200){
            const accessToken = response.data.access;
            localStorage.setItem("access_token", accessToken);
            axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
            window.location.replace("/");
        }
    }

  return (
    <div className={`${styles.container} pt-3`}>
        <Row>
            <div className='pb-5'>
                <h1>INICIO DE SESIÓN</h1>
            </div>
            <div className={`${styles.text_input_container} pb-4`}>
                <label className={styles.text_input_label}>Usuario</label>
                <input autoCapitalize="none" autoCorrect={"off"} type={'text'} value={username} onChange={e => setUsername(e.target.value)} className={styles.text_input}></input>
            </div>
            <div className={`${styles.text_input_container} pb-3`}>
                <label className={styles.text_input_label}>Contraseña</label>
                <input value={password} type={'password'} onChange={e => setPassword(e.target.value)} className={styles.text_input}></input>
            </div>
            <Button onClick={login} className={`${styles.button_green} mb-2`}>Ingresar</Button>
        </Row>
    </div>
  )
}
