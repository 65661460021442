import React, { useEffect, useState } from 'react'
import { Pagination } from 'react-bootstrap';
import axios from '../AxiosInstance'
import styles from '../styles/Producer.module.css'

export default function PaginationComponent({prop, func, limit}) {
    const paginationLimit = limit; // same as settings.py
    const [last, setLast] = useState(0);
    const [active, setActive] = useState(1);
    const [next, setNext] = useState("");
    const [prev, setPrev] = useState("");

    useEffect(() => {
        axios.get(prop  + '/').then((response) => {
            setNext(response.data.next);
            setPrev(response.data.prev);
            setLast(Math.ceil(response.data.count / paginationLimit));
          })
    }, []);

    const paginationGo = (page) => {
        axios.get(prop + '/?limit=' + paginationLimit + '&offset=' + paginationLimit * (page - 1) + "&page=" + page).then((response) => {
            func(response.data.results)
            setNext(response.data.next);
            setPrev(response.data.prev);
            setActive(page);
        })
    };
  return (
    <Pagination className={`${styles.pagination_container}`}>
        {active > 3 && <Pagination.Ellipsis />}
        {active > 2 && <Pagination.Item onClick={() => paginationGo(active-2)}>{active - 2}</Pagination.Item>}
        {active > 1 && <Pagination.Item onClick={() => paginationGo(active-1)}>{active - 1}</Pagination.Item>}
        <Pagination.Item active>{active}</Pagination.Item>
        {active < last - 0 && <Pagination.Item onClick={() => paginationGo(active+1)}>{active + 1}</Pagination.Item>}
        {active < last - 1 && <Pagination.Item onClick={() => paginationGo(active+2)}>{active + 2}</Pagination.Item>}
        {active < last - 2 && <Pagination.Ellipsis/>}
    </Pagination>
  )
}
