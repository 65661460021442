import React, { useEffect, useState } from 'react'
import styles from '../styles/Paddy.module.css'
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import axios from '../AxiosInstance'
import LoadingComponent from '../components/LoadingComponent'
import PaginationComponent from '../components/PaginationComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'

export default function KardexScreen() {
    const [results, setResults] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [silos, setSilos] = useState([]);

    const [dateFilter, setDateFilter] = useState(undefined);
    const [siloFilter, setSiloFilter] = useState(undefined);

    useEffect(() => {
        axios.get('kardexsilo/').then((response) => {
            setResults(response.data.results);
            setLoaded(true);
        })
        axios.get('silo_select/').then((response) => {
            setSilos(response.data);
        })
    }, []);

    const search = () => {
        let url = "kardexsilo/?";
        if(dateFilter !== undefined) url += '&fecha_hora=' + dateFilter;
        if(siloFilter !== undefined) url += '&silo=' + siloFilter;
        axios.get(url).then((response) => {
            setResults(response.data.results);
            setLoaded(true);
        })
    }

    const pullData = (data) => {
        setResults(data);
    }

    const readableTime = (data) => {
        if(data == null) return "-";
        const date = new Date(data);
        return date.toLocaleString();
    }
  return (
    <div>
        {loaded === false ? <LoadingComponent/>:
        <div className={`${styles.content} pt-5`}>
            <h1>Historial</h1>
            <div className={`${styles.inner_container} pt-5`}>
                <div className={`${styles.filter_top}`}>
                    <div className={`${styles.text_input_container} pb-3`}>
                        <label className={styles.text_input_label}>Fecha</label>
                        <input type="date" className={styles.text_input} onChange={x => setDateFilter(x.target.value)}></input>
                    </div>
                    <div className={`${styles.text_input_container} pb-3`}>
                        <label className={styles.text_input_label}>Silo</label>
                        <select className={styles.select_input} onChange={x => setSiloFilter(x.target.value)}>
                            <option value="">-- Seleccione una opción --</option>
                            {silos.map((silo, index) => (
                                <option value={silo.id}>{silo.nombre}</option>
                            ))}
                        </select>
                    </div>
                    <div className={`${styles.button_container}`}>
                        <Button onClick={search}>
                            <FontAwesomeIcon icon={faSearch} color="black"/>
                        </Button>
                    </div>
                </div>

                <table>
                    <tr>
                        <th/>
                        <th>Movimiento</th>
                        <th>KG Traslado</th>
                        <th>Lugar Salida</th>
                        <th>Fecha Hora</th>
                        <th>Silo</th>
                        <th>Lugar Entrada</th>
                    </tr>
                    {results.map((result, index) => (
                    <tr key={result.id}>
                        <th>{index + 1}</th>
                        <th>{result.movimiento === 0 ? "Entrada" : "Salida"}</th>
                        <th>{result.kg_translado}</th>
                        <th>{result.lugar_salida}</th>
                        <th>{readableTime(result.fecha_hora)}</th>
                        <th>{result.silo.nombre}</th>
                        <th>{result.lugar_entrada ? result.lugar_entrada.nombre : ""}</th>
                    </tr>
                    ))}
                </table>

                <PaginationComponent prop={"kardexsilo"} func={pullData} limit={14}/>

                <div className={`${styles.lower_bar}`}>
                <Link className={`${styles.lower_button_container}`} to="/silo">
                    <Button>
                        <FontAwesomeIcon icon={faChevronLeft} color="black"/>
                        <span>Regresar</span>
                    </Button>
                </Link>
            </div>
            </div>
        </div>}
    </div>
  )
}
