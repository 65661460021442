// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Home_content__HnpMW {\r\n    height: 40%;\r\n    color: #9DD879;\r\n    flex-grow: 1;\r\n    right: 0;\r\n    z-index: -2 !important;\r\n}\r\n\r\n.Home_bold__SzG3t {\r\n    font-weight: 700;\r\n    color: #9DD879;\r\n}\r\n\r\n.Home_button__nG-AV {\r\n    border-radius: 8px;\r\n    border: #78B54A 1px solid;\r\n    font-weight: 500;\r\n    width: auto;\r\n    cursor: pointer !important;\r\n    padding: 10px;\r\n}\r\n\r\n.Home_button__nG-AV:hover {\r\n    background-color: #78B54A;\r\n    color: whitesmoke;\r\n}\r\n\r\n", "",{"version":3,"sources":["webpack://./src/styles/Home.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,cAAc;IACd,YAAY;IACZ,QAAQ;IACR,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,gBAAgB;IAChB,WAAW;IACX,0BAA0B;IAC1B,aAAa;AACjB;;AAEA;IACI,yBAAyB;IACzB,iBAAiB;AACrB","sourcesContent":[".content {\r\n    height: 40%;\r\n    color: #9DD879;\r\n    flex-grow: 1;\r\n    right: 0;\r\n    z-index: -2 !important;\r\n}\r\n\r\n.bold {\r\n    font-weight: 700;\r\n    color: #9DD879;\r\n}\r\n\r\n.button {\r\n    border-radius: 8px;\r\n    border: #78B54A 1px solid;\r\n    font-weight: 500;\r\n    width: auto;\r\n    cursor: pointer !important;\r\n    padding: 10px;\r\n}\r\n\r\n.button:hover {\r\n    background-color: #78B54A;\r\n    color: whitesmoke;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "Home_content__HnpMW",
	"bold": "Home_bold__SzG3t",
	"button": "Home_button__nG-AV"
};
export default ___CSS_LOADER_EXPORT___;
