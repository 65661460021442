import React, { useEffect, useState } from 'react'
import styles from '../styles/Producer.module.css'
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import { Link } from 'react-router-dom'
import axios from '../AxiosInstance'
import LoadingComponent from '../components/LoadingComponent'
import PaginationComponent from '../components/PaginationComponent'

export default function ProducersScreen() {
    const [producers, setProducers] = useState();
    const [loaded, setLoaded] = useState(false);

    const [nameFilter, setNameFilter] = useState();
  
    useEffect(() => {
      axios.get('productor/').then((response) => {
        setProducers(response.data.results);
        setLoaded(true);
      })
    }, []);

    const search = () => {
        let url = "productor/?";
        if(nameFilter !== "") url += '&nombre=' + nameFilter;
        axios.get(url).then((response) => {
            setProducers(response.data.results);
            setLoaded(true);
        })
    }

    const pullData = (data) => {
        setProducers(data);
    }

  return (
    <div>
        {loaded === false ? <LoadingComponent/> :
        <div className={`${styles.content}`}>
            <div className={`${styles.inner_container} pt-5`}>

                <h1>Productores</h1>

                <div className={`${styles.filter_top}`}>
                    <div className={`${styles.text_input_container} pb-3`}>
                        <label className={styles.text_input_label}>Nombre</label>
                        <input className={styles.text_input} onChange={x => setNameFilter(x.target.value)}></input>
                    </div>
                    <div className={`${styles.button_container}`}>
                        <Button onClick={search}>
                            <FontAwesomeIcon icon={faSearch} color="black"/>
                        </Button>
                    </div>
                </div>

                <table>
                    <tr>
                        <th>#</th>
                        <th>Nombre</th>
                        <th></th>
                    </tr>
                    {producers.map((producer, index) => (
                    <tr>
                        <th>{index + 1}</th>
                        <th>{producer.nombre}</th>
                        <th>
                            <Link to={`/producer/edit?id=${producer.id}`} >
                                Editar
                            </Link>
                        </th>
                    </tr>
                    ))}
                </table>

                <PaginationComponent prop={"productor"} func={pullData} limit={14}/>

                <div className={`${styles.lower_bar}`}>
                    <Link className={`${styles.lower_button_container}`} to="/arrival">
                        <Button>
                            <FontAwesomeIcon icon={faChevronLeft} color="black"/>
                            <span>Regresar</span>
                        </Button>
                    </Link>
                    <Link className={`${styles.lower_button_container}`} to="/producer/create">
                        <Button>
                            <FontAwesomeIcon icon={faPlusCircle} color="black"/>
                            <span>Registrar Productor</span>
                        </Button>
                    </Link>
                </div>

            </div>
        </div>}
    </div>
  )
}
