import React from 'react'
import '../styles/Loading.css';

export default function LoadingComponent() {
  return (
    <div className="loading_screen">
        <div className="loading_spinner"></div>
  </div>
  )
}
