import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons/faPenToSquare'
import { faTruck } from '@fortawesome/free-solid-svg-icons/faTruck'
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser'
import React, { useEffect, useState } from 'react'
import styles from '../styles/Paddy.module.css'
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import axios from '../AxiosInstance'
import LoadingComponent from '../components/LoadingComponent'
import PaginationComponent from '../components/PaginationComponent'

export default function ArrivalScreen() {
    const [paddys, setPaddys] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [producers , setProducers] = useState([]);

    const [dateFilter, setDateFilter] = useState();
    const [producerFilter, setProducerFilter] = useState();

    useEffect(() => {
        axios.get('recepcion/').then((response) => {
            setPaddys(response.data.results);
            setLoaded(true);
        })
        axios.get('productor_select/').then((response) => {
            setProducers(response.data);
        })
    }, []);

    const search = () => {
        let url = "recepcion/?";
        if(dateFilter !== undefined) url += '&hora_recepcion=' + dateFilter;
        if(producerFilter !== "") url += '&productor=' + producerFilter;
        axios.get(url).then((response) => {
            setPaddys(response.data.results);
            setLoaded(true);
        })
    }

    const pullData = (data) => {
        setPaddys(data);
    }

    const readableTime = (data) => {
        if(data == null) return "-";
        const date = new Date(data);
        return date.toLocaleString();
    }
  return (
    <div>
        {loaded === false ? <LoadingComponent/> :
        <div className={`${styles.content} pt-4`}>
            <h1>Recepciones de paddy</h1>
            <div className={`${styles.inner_container} pt-2`}>
                <div className={`${styles.filter_top}`}>
                    <div className={`${styles.text_input_container} pb-3`}>
                        <label className={styles.text_input_label}>Fecha</label>
                        <input type="date" className={styles.text_input} onChange={x => setDateFilter(x.target.value)}></input>
                    </div>
                    <div className={`${styles.text_input_container} pb-3`}>
                        <label className={styles.text_input_label}>Productor</label>
                        <select className={styles.select_input} onChange={x => setProducerFilter(x.target.value)}>
                            <option value="">-- Seleccione una opción --</option>
                            {producers.map((producer, index) => (
                                <option value={producer.id}>{producer.nombre}</option>
                            ))}
                        </select>
                    </div>
                    <div className={`${styles.button_container}`}>
                        <Button onClick={search}>
                            <FontAwesomeIcon icon={faSearch} color="black"/>
                        </Button>
                    </div>
                </div>
                <table>
                    <tr>
                        <th/>
                        <th>Fecha y Hora</th>
                        <th>Productor</th>
                        <th>Camión</th>
                        <th>KG</th>
                        <th>KG Restantes</th>
                        <th>Humedad</th>
                        <th>Temperatura</th>
                        <th>Vaciado</th>
                    </tr>
                    {paddys.map((paddy, index) => (
                    <tr key={paddy.id}>
                        <th>{index + 1}</th>
                        <th>{readableTime(paddy.hora_recepcion)}</th>
                        <th>{paddy.productor.nombre}</th>
                        <th>{paddy.camion.identificador}</th>
                        <th>{paddy.kg_estimados?.toLocaleString()}</th>
                        <th>{paddy.kg_restantes?.toLocaleString()}</th>
                        <th>{paddy.humedad_recepcion}</th>
                        <th>{paddy.temperatura_recepcion}</th>
                        <th>{paddy.vaciada === false ? "No" : "Sí"}</th>
                    </tr>
                    ))}
                </table>
                <PaginationComponent prop={"recepcion"} func={pullData} limit={14}/>
                <div className={`${styles.lower_bar}`}>
                    <Link className={`${styles.lower_button_container}`} to="/producer">
                        <Button>
                            <FontAwesomeIcon icon={faUser} color="black"/>
                            <span>Productores</span>
                        </Button>
                    </Link>
                    <Link className={`${styles.lower_button_container}`} to="/truck">
                        <Button>
                            <FontAwesomeIcon icon={faTruck} color="black"/>
                            <span>Camiones</span>
                        </Button>
                    </Link>
                    <Link className={`${styles.lower_button_container}`} to="/arrival/create">
                        <Button>
                            <FontAwesomeIcon icon={faPenToSquare} color="black"/>
                            <span>Nueva Recepción</span>
                        </Button>
                    </Link>
                    <Link className={`${styles.lower_button_container}`} to="/arrival/empty">
                        <Button>
                            <FontAwesomeIcon icon={faPenToSquare} color="black"/>
                            <span>Vaciado</span>
                        </Button>
                    </Link>
                </div>
            </div>
        </div>}
    </div>
  )
}
