import React, { useEffect, useState } from 'react'
import styles from '../styles/Silo.module.css'
import { Button } from 'react-bootstrap';
import axios from '../AxiosInstance'
import LoadingComponent from '../components/LoadingComponent'
import { Link } from 'react-router-dom';

export default function SiloScreen() {
    const [silos, setSilos] = useState([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        axios.get('silo/').then((response) => {
            setSilos(response.data.results);
            setLoaded(true);
        });
    }, []);

    const readableTime = (data) => {
        if(data == null) return "-";
        const date = new Date(data);
        return date.toLocaleString();
    }
  return (
    <div>
        {loaded === false ? <LoadingComponent/> :
        <div className={`${styles.content} pt-4`}>
            <h1>Inventario de Silos</h1>
            <div className={`${styles.inner_container} pt-2`}>
                <table>
                    <tr>
                        <th/>
                        <th>Silo</th>
                        <th>Kg. Totales</th>
                        <th>Kg. Trillables</th>
                        <th>Impureza</th>
                        <th>Humedad</th>
                        <th>Capacidad Max</th>
                        <th>% de llenado</th>
                        <th>Última Actualización</th>
                    </tr>
                    {silos.map((silo, index) => (
                    <tr>
                        <th>{index + 1}</th>
                        <th>{silo.nombre}</th>
                        <th>{silo.stock?.toLocaleString()}</th>
                        <th>{silo.kilogramos_trillables?.toLocaleString()}</th>
                        <th>{silo.impureza?.toFixed(2)}</th>
                        <th>{silo.humedad?.toFixed(2)}</th>
                        <th>{silo.capacidad_maxima?.toLocaleString()}</th>
                        <th>{silo.porcentaje_llenado.toFixed(0)}%</th>
                        <th>{readableTime(silo.ultima_actualizacion)}</th>
                    </tr>
                    ))}
                </table>
                <div className={`${styles.right_bar}`}>
                    <Link className={`${styles.right_button_container}`} to="/silo/empty">
                        <Button>
                            <span>Vaciar Silo</span>
                        </Button>
                    </Link>
                    <Link className={`${styles.right_button_container}`} to="/silo/kardex">
                        <Button>
                            <span>Llenado Historico</span>
                        </Button>
                    </Link>
                </div>
            </div>
        </div>}
    </div>
  )
}
