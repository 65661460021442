import React, { useEffect, useState } from 'react'
import styles from '../styles/Paddy.module.css'
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import axios from '../AxiosInstance'
import LoadingComponent from '../components/LoadingComponent'
import PaginationComponent from '../components/PaginationComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'

export default function MoveHistoryScreen() {
    const [tanks, setTanks] = useState([]);
    const [paddys, setPaddys] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const [dateFilter, setDateFilter] = useState(undefined);
    const [tankFilter, setTankFilter] = useState(undefined);

    useEffect(() => {
        axios.get('translado/').then((response) => {
            setPaddys(response.data.results);
            setLoaded(true);
        })
        axios.get('tanque_select/').then((response) => {
            setTanks(response.data);
        })
    }, []);

    const search = () => {
        let url = "translado/?";
        if(dateFilter !== undefined) url += '&fecha_hora=' + dateFilter;
        if(tankFilter !== undefined) url += '&tanque=' + tankFilter;
        axios.get(url).then((response) => {
            setPaddys(response.data.results);
            setLoaded(true);
        })
    }

    const pullData = (data) => {
        setPaddys(data);
    }

    const readableTime = (data) => {
        if(data == null) return "-";
        const date = new Date(data);
        return date.toLocaleString();
    }
  return (
    <div>
        {loaded === false ? <LoadingComponent/>:
        <div className={`${styles.content} pt-5`}>
            <h1>Historial de traslado</h1>
            <div className={`${styles.inner_container} pt-5`}>

                <div className={`${styles.filter_top}`}>
                    <div className={`${styles.text_input_container} pb-3`}>
                        <label className={styles.text_input_label}>Fecha</label>
                        <input type="date" className={styles.text_input} onChange={x => setDateFilter(x.target.value)}></input>
                    </div>
                    <div className={`${styles.text_input_container} pb-3`}>
                        <label className={styles.text_input_label}>Tanque</label>
                        <select className={styles.select_input} onChange={x => setTankFilter(x.target.value)}>
                            <option value="">-- Seleccione una opción --</option>
                            {tanks.map((tank, index) => (
                                <option value={tank.id}>{tank.nombre}</option>
                            ))}
                        </select>
                    </div>
                    <div className={`${styles.button_container}`}>
                        <Button onClick={search}>
                            <FontAwesomeIcon icon={faSearch} color="black"/>
                        </Button>
                    </div>
                </div>

                <table>
                    <tr>
                        <th/>
                        <th>Tanque/Lister</th>
                        <th>KG estimados</th>
                        <th>Humedad</th>
                        <th>Silo</th>
                        <th>Fecha/Hora</th>
                    </tr>
                    {paddys.map((paddy, index) => (
                    <tr key={paddy.id}>
                        <th>{index + 1}</th>
                        <th>{paddy.tanque.nombre}</th>
                        <th>{paddy.kg_translado}</th>
                        <th>{paddy.humedad}</th>
                        <th>{paddy.silo.nombre}</th>
                        <th>{readableTime(paddy.fecha_hora)}</th>
                    </tr>
                    ))}
                </table>
                
                <PaginationComponent prop={"translado"} func={pullData} limit={14}/>

                <div className={`${styles.lower_bar}`}>
                    <Link className={`${styles.lower_button_container}`} to="/tank/move">
                        <Button>
                            <FontAwesomeIcon icon={faChevronLeft} color="black"/>
                            <span>Regresar</span>
                        </Button>
                    </Link>
                </div>
            </div>
        </div>}
    </div>
  )
}
