import React, {useEffect} from 'react';
import { Routes, Route } from 'react-router-dom';
import LoginScreen from './screens/LoginScreen';
import HomeScreen from './screens/HomeScreen';
import ArrivalScreen from './screens/ArrivalScreen';
import TankScreen from './screens/TankScreen';
import SiloScreen from './screens/SiloScreen';
import NewArrival from './screens/CRUDS/NewArrival';
import ProducersScreen from './screens/ProducerScreen';
import NewProducer from './screens/CRUDS/NewProducer';
import EditProducer from './screens/CRUDS/EditProducer';
import FillTank from './screens/CRUDS/FillTank';
import FillHistoryScreen from './screens/FillHistoryScreen';
import UpdateTank from './screens/CRUDS/UpdateTank';
import UpdateHistoryScreen from './screens/UpdateHistoryScreen';
import MoveToSiloScreen from './screens/CRUDS/MoveToSiloScreen';
import RealKG from './screens/CRUDS/RealKG';
import MoveHistoryScreen from './screens/MoveHistoryScreen';
import EmptySilo from './screens/CRUDS/EmptySilo';
import EmptyHistoryScreen from './screens/EmptyHistoryScreen';
import KardexScreen from './screens/KardexScreen';
import CreateTruckScreen from './screens/CRUDS/CreateTruckScreen';
import ArrivalEmpty from './screens/CRUDS/ArrivalEmpty';
import TruckScreen from './screens/TruckScreen';

function LoginRedirect() {
    useEffect(() => {
        window.location.replace('/login');
    }, []);
    return (<div></div>);
}

export default function RouteContainer() {
    let authenticated = localStorage.getItem('access_token') !== null;
    return (
        <Routes>
            <Route path="/login" element={<LoginScreen/>}>Login</Route>

            {authenticated === true && <>
                <Route path="/" element={<HomeScreen/>}>Home</Route>
                <Route path="/arrival" element={<ArrivalScreen/>}>Recepción</Route>
                <Route path="/truck" element={<TruckScreen/>}>Camiones</Route>
                <Route path="/tank" element={<TankScreen/>}>Tanques</Route>
                <Route path="/silo" element={<SiloScreen/>}>Silos</Route>
                <Route path="/producer" element={<ProducersScreen/>}>Productores</Route>
                <Route path="/tank/fill/history" element={<FillHistoryScreen/>}>Historial de llenado</Route>
                <Route path="/tank/update/history" element={<UpdateHistoryScreen/>}>Historial de actualizaciones</Route>
                <Route path="/tank/move/history" element={<MoveHistoryScreen/>}>Historial de movimientos</Route>
                <Route path="/silo/empty/history" element={<EmptyHistoryScreen/>}>Historial de vaciados</Route>
                <Route path="/silo/kardex" element={<KardexScreen/>}>Llenado Historico</Route>

                <Route path="/arrival/create" element={<NewArrival/>}>Nueva Recepción</Route>
                <Route path="/arrival/empty" element={<ArrivalEmpty/>}>Vaciado de Silo</Route>
                <Route path="/producer/create" element={<NewProducer/>}>Nuevo productor</Route>
                <Route path="/producer/edit" element={<EditProducer/>}>Editar productor</Route>
                <Route path="/truck/create" element={<CreateTruckScreen/>}>Camiones</Route>
                <Route path="/tank/fill" element={<FillTank/>}>Llenar tanque</Route>
                <Route path="/tank/update" element={<UpdateTank/>}>Actualizar tanque</Route>
                <Route path="/tank/move" element={<MoveToSiloScreen/>}>Traslado a silo</Route>
                <Route path="/tank/realkg" element={<RealKG/>}>KG Reales</Route>
                <Route path="/silo/empty" element={<EmptySilo/>}>Vaciado de Silo</Route>
            </>
            }

            <Route path="*" element={<LoginRedirect />}/>
        </Routes>
    )
}
